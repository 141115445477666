<template>
  <v-app>
    <loading :active.sync="is_call_api"></loading>
    <div style="background-color: rgb(243 246 249)">
      <h1 class="mt-5 mb-5" style="color: #3f4254">
        Quản lí bảng chọn trình độ cho nhóm sản phẩm {{ category_title }}
      </h1>
    </div>

    <div class="pt-5 pl-5 pr-5">
      <v-btn
        rounded
        color="primary"
        class="mb-5"
        x-small
        large
        @click="openDiaLogLevelSelector"
      >
        <v-icon color="white" dark>mdi-plus</v-icon
        ><span style="color: white">Thêm Level Selector</span>
      </v-btn>
      <v-btn
        rounded
        color="cyan"
        class="mb-5 ml-5"
        x-small
        large
        @click="openDialogSort"
      >
        <v-icon color="white" dark>mdi-sort</v-icon
        ><span style="color: white">Sắp xếp lại</span>
      </v-btn>
      <div>
        <v-row>
          <v-col cols="12">
            <div class="table-responsive">
              <table
                class="table datatable-bordered table-head-custom table-vertical-center table-head-bg"
              >
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Level</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(level_selector, i) in level_selectors">
                    <tr :key="i">
                      <td scope="row">{{ i + 1 }}</td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                          v-if="level_selector.name.vi"
                        >
                          <span class="cyan--text">VI:</span>
                          {{ level_selector.name.vi }}
                        </p>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                          v-else
                        >
                          <span class="cyan--text">EN:</span>
                          {{ level_selector.name.en }}
                        </p>
                      </td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ level_selector.start_level }} ->
                          {{ level_selector.end_level }}
                        </p>
                      </td>
                      <td>
                        <div style="display: flex">
                          <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-success btn-sm"
                                v-bind="attrs"
                                v-on="on"
                                @click="editLevelSelector(i)"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color=""
                                    >mdi-square-edit-outline</v-icon
                                  >
                                </span>
                              </button>
                            </template>
                            <span>Edit</span>
                          </v-tooltip>
                          <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-danger btn-sm ml-4"
                                v-bind="attrs"
                                v-on="on"
                                @click="deleteLevelSelector(level_selector.id)"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color="">mdi-delete</v-icon>
                                </span>
                              </button>
                            </template>
                            <span>Delete</span>
                          </v-tooltip>
                        </div>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>

    <v-row justify="center">
      <v-dialog
        v-model="dialogLevelSelector"
        max-width="1400px"
        scrollable
        persistent
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline" v-if="type_form === 'add'"
                >Add Level Selector</span
              >
              <span class="headline" v-else>Edit Level Selector</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogLevelSelector = false" right
                >mdi-close</v-icon
              >
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="2" class="p-0 m-0"></v-col>
                    <v-col cols="8">
                      <v-radio-group
                        v-model="language"
                        row
                        class="mb-0 mt-0 pb-0"
                        dense
                      >
                        <v-radio label="Việt Nam" value="vi"></v-radio>
                        <v-radio label="English" value="en"></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Name:</label></v-col
                    >
                    <v-col
                      cols="8"
                      class="p-0 m-0 ml-4"
                      v-if="language === 'vi'"
                      ><v-text-field
                        dense
                        v-model="name_input.vi"
                        filled
                      ></v-text-field
                    ></v-col>
                    <v-col
                      cols="8"
                      class="p-0 m-0 ml-4"
                      v-if="language === 'en'"
                      ><v-text-field
                        dense
                        v-model="name_input.en"
                        filled
                      ></v-text-field
                    ></v-col>
                  </v-row>
                  <v-row class="mt-8">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Start Level:</label></v-col
                    >
                    <v-col cols="8" class="ml-4 p-0">
                      <v-text-field
                        dense
                        v-model="start_level_input"
                        type="number"
                        filled
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="mt-8">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">End Level:</label></v-col
                    >
                    <v-col cols="8" class="ml-4 p-0">
                      <v-text-field
                        dense
                        v-model="end_level_input"
                        type="number"
                        filled
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="mt-8">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Abilities:</label></v-col
                    >
                    <v-col cols="8" class="ml-4 p-0">
                      <v-row>
                        <v-col cols="5" class="p-0">
                          <v-text-field
                            outlined
                            auto-grow
                            dense
                            rows="1"
                            class="ml-3 mt-4 p-0 text-body-1"
                            v-model="abilitie_input"
                            placeholder="Nhập ..."
                            @keydown.enter="addAbilitie"
                          ></v-text-field>
                        </v-col>
                        <v-btn
                          color="cyan"
                          class="ma-2 mt-4 font-weight-bold"
                          outlined
                          small
                          @click="addAbilitie"
                        >
                          <v-icon small left>mdi-check</v-icon>
                          Lưu
                        </v-btn>
                      </v-row>
                      <div
                        style="background-color: #f2f2f2; width: 100%"
                        class="pb-2"
                      >
                        <p
                          v-if="
                            language === 'vi' && abilities_input.vi.length === 0
                          "
                          class="text-center pt-2"
                        >
                          no data
                        </p>
                        <p
                          v-if="
                            language === 'en' && abilities_input.en.length === 0
                          "
                          class="text-center pt-2"
                        >
                          no data
                        </p>
                        <template v-if="language === 'vi'">
                          <template v-for="(abilitie, i) in abilities_input.vi">
                            <v-btn
                              style="background-color: #00bcd4; color: white"
                              dense
                              class="ml-2 mt-2"
                              :key="i"
                            >
                              {{ abilitie }}
                              <v-icon
                                dense
                                color="white"
                                class="ml-1"
                                dark
                                @click="delAbilitie(i)"
                                >mdi-close</v-icon
                              >
                            </v-btn>
                          </template>
                        </template>
                        <template v-if="language === 'en'">
                          <template v-for="(abilitie, i) in abilities_input.en">
                            <v-btn
                              style="background-color: #00bcd4; color: white"
                              dense
                              class="ml-2 mt-2"
                              :key="i"
                            >
                              {{ abilitie }}
                              <v-icon
                                dense
                                color="white"
                                class="ml-1"
                                dark
                                @click="delAbilitie(i)"
                                >mdi-close</v-icon
                              >
                            </v-btn>
                          </template>
                        </template>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row class="mt-8">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Words:</label></v-col
                    >
                    <v-col cols="8" class="ml-4 p-0">
                      <v-row>
                        <v-col cols="5" class="p-0">
                          <v-text-field
                            outlined
                            auto-grow
                            dense
                            rows="1"
                            class="ml-3 mt-4 p-0 text-body-1"
                            v-model="word_input"
                            placeholder="Nhập ..."
                            @keydown.enter="addWord"
                          ></v-text-field>
                        </v-col>
                        <v-btn
                          color="cyan"
                          class="ma-2 mt-4 font-weight-bold"
                          outlined
                          small
                          @click="addWord"
                        >
                          <v-icon small left>mdi-check</v-icon>
                          Lưu
                        </v-btn>
                      </v-row>
                      <div
                        style="background-color: #f2f2f2; width: 100%"
                        class="pb-2"
                      >
                        <p
                          v-if="words_input.length === 0"
                          class="text-center pt-2"
                        >
                          no data
                        </p>
                        <template v-for="(word, i) in words_input">
                          <v-btn
                            style="background-color: #3949ab; color: white"
                            dense
                            class="ml-2 mt-2"
                            :key="i"
                          >
                            {{ word }}
                            <v-icon
                              dense
                              color="white"
                              class="ml-1"
                              dark
                              @click="delWord(i)"
                              >mdi-close</v-icon
                            >
                          </v-btn>
                        </template>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form === 'add'"
              @click="btnAddOrEditLevelSelector(1)"
            >
              Add
            </button>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form === 'update'"
              @click="btnAddOrEditLevelSelector(2)"
            >
              Update
            </button>
            <button
              class="btn text-uppercase mr-2"
              style="color: #f64e60"
              @click="dialogLevelSelector = false"
            >
              Close
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row justify="center">
      <v-dialog v-model="dialogSort" scrollable persistent max-width="600px">
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline">Sắp xếp lại ý kiến , nhận xét</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogSort = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <draggable :list="data_draggable">
                    <ul
                      class="list-unstyled cursor-pointer"
                      v-for="(level_selector, i) in data_draggable"
                      :key="i"
                    >
                      <b-media tag="li">
                        <h5 class="mt-0 mb-1" style="color: #3699ff">
                          {{ level_selector.name.vi }}
                        </h5>
                      </b-media>
                    </ul>
                  </draggable>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              outlined
              text
              @click="sortLevelSelector"
              >Update</v-btn
            >
            <v-btn color="red darken-1" text @click="dialogSort = false"
              >Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-app>
</template>

<script>
import draggable from "vuedraggable";
import "vue-loading-overlay/dist/vue-loading.css";
import ApiService from "@/service/api.service";
import Swal from "sweetalert2";

export default {
  name: "LevelSelector",
  components: {
    draggable,
    Loading: () => import("vue-loading-overlay"),
  },
  data() {
    return {
      is_call_api: false,
      category_title: "",
      level_selectors: [],
      level_selector_id: null,
      language: "vi",
      type_form: null,
      dialogLevelSelector: false,
      name_input: {
        en: "",
        vi: "",
      },
      start_level_input: null,
      end_level_input: null,
      abilities_input: {
        en: [],
        vi: [],
      },
      words_input: [],
      abilitie_input: "",
      word_input: "",
      data_draggable: [],
      dialogSort: false,
    };
  },
  computed: {
    category_id: function () {
      return this.$route.params.id;
    },
  },
  created() {
    this.getAllLevelSelector();
  },
  methods: {
    async getAllLevelSelector() {
      let vm = this;
      try {
        vm.is_call_api = true;
        let res = await ApiService.get(
          "prep-app/product-category/" + this.category_id + "/level-selector"
        );
        if (res.status === 200) {
          vm.category_title = res.data.category;
          vm.level_selectors = res.data.data;
          vm.is_call_api = false;
        }
      } catch (error) {
        vm.is_call_api = false;
        console.log(error);
      }
    },
    openDiaLogLevelSelector() {
      this.type_form = "add";
      this.name_input = {
        en: "",
        vi: "",
      };
      this.start_level_input = null;
      this.end_level_input = null;
      this.abilities_input = {
        en: [],
        vi: [],
      };
      this.words_input = [];
      this.abilitie_input = "";
      this.word_input = "";
      this.language = "vi";
      this.dialogLevelSelector = true;
    },
    openDialogSort() {
      this.data_draggable = JSON.parse(JSON.stringify(this.level_selectors));
      this.dialogSort = true;
    },
    editLevelSelector(index) {
      this.type_form = "update";
      let data = this.level_selectors[index];
      this.level_selector_id = data.id;
      this.name_input = JSON.parse(JSON.stringify(data.name));
      this.start_level_input = data.start_level;
      this.end_level_input = data.end_level;
      this.abilities_input = JSON.parse(JSON.stringify(data.abilities));
      this.words_input = JSON.parse(JSON.stringify(data.words));
      this.language = "vi";
      this.dialogLevelSelector = true;
    },
    deleteLevelSelector(level_selector_id) {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          ApiService.delete(
            "prep-app/product-category/" +
              this.category_id +
              "/level-selector/" +
              level_selector_id
          )
            .then(function (res) {
              if (res.status === 200) {
                vm.getAllLevelSelector();
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    },
    validateForm() {
      let flat = true;
      if (this.name_input.vi === "" && this.name_input.en === "") {
        this.$toasted.error("Hãy nhập name !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      if (this.start_level_input === "" || this.start_level_input == null) {
        this.$toasted.error("Hãy nhập start level và đúng hợp lệ !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      } else {
        if (this.start_level_input <= 0) {
          this.$toasted.error("Giá trị start level phải lớn hơn 0!!", {
            theme: "toasted-primary",
            position: "top-right",
            duration: 4000,
          });
          flat = false;
        }
      }
      if (this.end_level_input === "" || this.end_level_input == null) {
        this.$toasted.error("Hãy nhập end level và đúng hợp lệ !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      } else {
        if (this.end_level_input <= 0) {
          this.$toasted.error("Giá trị end level phải lớn hơn 0!!", {
            theme: "toasted-primary",
            position: "top-right",
            duration: 4000,
          });
          flat = false;
        }
      }
      if (this.start_level_input >= this.end_level_input) {
        this.$toasted.error(
          "Giá trị start level không thể lớn hơn hoặc bằng giá trị end level!!",
          { theme: "toasted-primary", position: "top-right", duration: 4000 }
        );
        flat = false;
      }
      return flat;
    },
    async btnAddOrEditLevelSelector(type) {
      let validateForm = this.validateForm();
      if (!validateForm) {
        return;
      }
      let vm = this;
      let data = {
        name: JSON.stringify(this.name_input),
        start_level: this.start_level_input,
        end_level: this.end_level_input,
        abilities: JSON.stringify(this.abilities_input),
        words: JSON.stringify(this.words_input),
      };
      this.is_call_api = true;
      if (type === 1) {
        data.offset = this.level_selectors.length + 1;
        await ApiService.post(
          "prep-app/product-category/" + this.category_id + "/level-selector",
          data
        )
          .then(function (res) {
            if (res.status === 200) {
              vm.getAllLevelSelector();
              vm.$toasted.success("Tạo thành công !", {
                theme: "toasted-primary",
                position: "top-right",
                duration: 4000,
              });
              vm.is_call_api = false;
              vm.dialogLevelSelector = false;
            }
          })
          .catch(function (error) {
            vm.is_call_api = false;
            if (error.response.status === 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      } else {
        await ApiService.put(
          "prep-app/product-category/" +
            this.category_id +
            "/level-selector/" +
            this.level_selector_id,
          data
        )
          .then(function (res) {
            if (res.status === 200) {
              vm.getAllLevelSelector();
              vm.$toasted.success("Sửa thành công !", {
                theme: "toasted-primary",
                position: "top-right",
                duration: 4000,
              });
              vm.is_call_api = false;
              vm.dialogLevelSelector = false;
            }
          })
          .catch(function (error) {
            vm.is_call_api = false;
            if (error.response.status === 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      }
    },
    showError(data_error) {
      for (let i = 0; i < data_error.length; i++) {
        this.$toasted.error(data_error[i] + " !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
      }
    },
    addAbilitie() {
      if (this.abilitie_input === "" || this.abilitie_input == null) {
        this.$toasted.error("Hãy nhập kỹ năng biết!!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        return;
      }
      if (this.language === "vi") {
        this.abilities_input.vi.push(this.abilitie_input);
      } else if (this.language === "en") {
        this.abilities_input.en.push(this.abilitie_input);
      }
      this.abilitie_input = "";
    },
    delAbilitie(index) {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          if (vm.language === "vi") {
            vm.abilities_input.vi.splice(index, 1);
          } else if (vm.language === "en") {
            vm.abilities_input.en.splice(index, 1);
          }
        }
      });
    },
    addWord() {
      if (this.word_input === "" || this.word_input == null) {
        this.$toasted.error("Hãy nhập từ vựng biết!!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        return;
      }
      this.words_input.push(this.word_input);
      this.word_input = "";
    },
    delWord(index) {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          vm.words_input.splice(index, 1);
        }
      });
    },
    sortLevelSelector() {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want save?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          let items = this.data_draggable.map((item, index) => {
            return {
              level_selector_id: item.id,
              offset: index + 1,
            };
          });
          let data = {
            data_sort: items,
          };
          vm.is_call_api = true;
          ApiService.post(
            "prep-app/product-category/" +
              this.category_id +
              "/level-selector/sort",
            data
          )
            .then(function (res) {
              if (res.status === 200) {
                vm.getAllLevelSelector();
                vm.$toasted.success("Sắp xếp thành công !", {
                  theme: "toasted-primary",
                  position: "top-right",
                  duration: 4000,
                });
                vm.is_call_api = false;
                vm.dialogSort = false;
              }
            })
            .catch(function (error) {
              vm.is_call_api = false;
              console.log(error);
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.table.table-head-bg thead th {
  background-color: #2196f3eb !important;
  color: white !important;
}
</style>
